import "../util/SplitText.js";

let sliderClass = "j-ahi--slick";

var initializeBlock = function($block, $attributes) {
	let elem = $block;
	if (!$block.hasClass(sliderClass)) {
		elem = $block.find("." + sliderClass);
	}

	let parent_container = elem.closest('.ao-animated-hero-intro').attr('id');
	parent_container = '#' + parent_container;

	elem.each(function() {
		$(this).slick({
			'slide' : '.ao-ahi--slide',
			'rows' : 0,
			'slidesToShow' : 1,
			'slidesToScroll' : 1,
			'infinite' : false,
			'speed' : 500,
			'dots' : false,
			'arrows' : false,
			'fade' : true,
			'adaptiveHeight' : true,
			'draggable' : false,
		});
	});
};

// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + sliderClass).each(function() { 
		initializeBlock($(this), false);
	});

	var split_content = '.ao-ahi--split';
	if ( $(split_content).length > 0 ) {
		var split = new SplitText($(split_content), {
			type: "lines",
			linesClass: "ao-ahi--initial-content--lines",
		});

		$(window).resize(function() {
			split.revert();
			clearTimeout(window.resizedFinished);
			window.resizedFinished = setTimeout(function(){
				var split = new SplitText($(split_content), {
					type: "lines",
					linesClass: "ao-ahi--initial-content--lines",
				});
			}, 250);
		});
	}

	setTimeout(function(){
		$('.ao-ahi--initial-content').addClass('anim-in');
	},1000);

	setTimeout(function(){
		$('.ao-ahi--bg').addClass('anim-in');
	},2500);

	setTimeout(function(){
		$("." + sliderClass).slick('slickNext');
		$('.ao-ahi--animated-content').addClass('anim-in');
	},4500);

});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=animated-hero-intro", initializeBlock);
}